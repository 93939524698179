import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"

import { MEDIA_MOBILE } from "../const"
import Layout from "../components/layout"
import typography from "../utils/typography"
import Heading2 from "../components/heading2"
import Metadata from "../components/metadata"
import Share from "../components/share"

import { BlogPostQuery } from "../../types/graphql-types"
import { BlogPostPageContext } from "../gatsby-node"

interface Props {
  data: BlogPostQuery
  pageContext: BlogPostPageContext
  location: Location
}

const Post: React.FC<Props> = ({ data, pageContext, location }) => {
  const { title, publishDate, content } = data.contentfulBlogPost

  return (
    <Layout>
      <Metadata
        title={title}
        pagePath={location.pathname}
        description={content.childMarkdownRemark.excerpt}
      />
      <Wrapper>
        <Heading2 feedUrl="/rss-blog.xml">Blog</Heading2>

        <DateTime>
          <time dateTime={publishDate}>{publishDate}</time>
        </DateTime>
        <Title>{title}</Title>

        <Body
          dangerouslySetInnerHTML={{
            __html: content.childMarkdownRemark.html,
          }}
        />

        <ShareWrapper>
          <Share
            pagePath={location.pathname}
            title={title}
            description={content.childMarkdownRemark.excerpt}
          />
        </ShareWrapper>

        <NextPrevPostLinks>
          <div>
            {pageContext.previous && (
              <PostLink
                to={`/blog/post/${pageContext.previous.slug}`}
                rel="next"
              >
                <PostLinkDateTime>
                  {pageContext.previous.publishDate.replace(/(^.+)T.+/, "$1")}
                  <wbr />T
                  {pageContext.previous.publishDate.replace(/^.+T(.+)$/, "$1")}
                </PostLinkDateTime>
                {pageContext.previous.title}
              </PostLink>
            )}
          </div>
          <div>
            {pageContext.next && (
              <PostLink to={`/blog/post/${pageContext.next.slug}`} rel="prev">
                <PostLinkDateTime>
                  {pageContext.next.publishDate.replace(/(^.+)T.+/, "$1")}
                  <wbr />T
                  {pageContext.next.publishDate.replace(/^.+T(.+)$/, "$1")}
                </PostLinkDateTime>
                {pageContext.next.title}
              </PostLink>
            )}
          </div>
        </NextPrevPostLinks>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query BlogPost($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      id
      content {
        childMarkdownRemark {
          html
          excerpt
        }
      }
      title
      publishDate
    }
  }
`

export default Post

const Wrapper = styled.div`
  box-sizing: border-box;
  max-width: 40em;
  margin: 2rem auto;

  @media (${MEDIA_MOBILE}) {
    width: 100%;
    padding: 0 20px;
    padding-top: 0;
  }
`
const Title = styled.h3`
  font-size: 1.6em;
  line-height: 1.4;
  margin-bottom: 20px;
`
const DateTime = styled.aside`
  margin-bottom: 20px;
  font-size: smaller;
`
const Body = styled.div`
  line-height: 1.8;
  word-wrap: break-word;
  margin-bottom: 40px;

  ${typography.toString()}

  a {
    text-decoration: underline dotted;
  }
`
const ShareWrapper = styled.div`
  margin-bottom: 40px;
`
const NextPrevPostLinks = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #333;
  padding-top: 20px;

  > div {
    min-width: 50%;
    max-width: 15em;
  }

  > div:last-child {
    text-align: right;
  }
`
const PostLink = styled(Link)`
  display: block;

  @media (${MEDIA_MOBILE}) {
    font-size: smaller;
  }
`
const PostLinkDateTime = styled.time`
  display: block;
  font-size: smaller;
  margin-bottom: 0.5em;
`
